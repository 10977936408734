import { Controller } from 'stimulus'; 
import {enter, leave} from 'el-transition'; 

export default class extends Controller {
  
  

  connect() {
    console.log("hello from StimulusJS")
  }
}


   